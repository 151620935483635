<template>
  <div>
    <div v-if="loading" class="text-center"><b-spinner /><br/> carregando impressão....</div>

    <vue-html2pdf
      :show-layout="show"
      :float-layout="!show"
 
      :preview-modal="!show"
      :paginate-elements-by-height="orientationPDF=='landscape'?800:1400"
      :filename="filename"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      :pdf-orientation="orientationPDF"
      :pdf-content-width="orientationPDF=='landscape'?`100%`:`50%`"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
      @progress="prg($event)"
      :pagebreak="{mode:'avoid-all'}"
      :enableDownload="enableDownload"
    

 
    >
      <section slot="pdf-content">
       <div class="relatorio" :style="orientationPDF=='landscape'?'width:1100px; padding-left:20px; padding-top:20px':''">
          <div class="header">
            <img src="@/assets/logo.png" style="width:70px; max-height: 70px; height:auto" />
            <div class="text-left">
              <h3 class="text-center">Saúde Social</h3>
              <h4 class="text-center" v-html="titulo"></h4>

              <small class="text-left" v-html="filtros"></small>
            </div>
            <div class="print_info text-right">
              <b>Data Impressão</b><br />
              <small>{{ data }}</small>
            </div>
          </div>
  
            
          <slot ></slot>
        
          <div class="footer"></div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import moment from "moment";

export default {
  components: {
    VueHtml2pdf,
  },
  props: {
    printNow: { type: Boolean, default: false },
    filename: { type: String, default: "web" },
    titulo: String,
    filtros: String,
    show:{type:Boolean, default:false},
    orientationPDF:{type:String,default:"portrait"},
    enableDownload:{
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    //if (this.printNow) {
    this.$refs.html2Pdf.generatePdf();
    //}
  },
  data() {
    return {
      user: this.$store.state.auth,
      data: moment().format("DD/MM/YY HH:mm"),
      loading: true,
    };
  },
  watch: {
    printNow: function () {
      if (this.printNow) {
        this.$refs.html2Pdf.generatePdf();
      }
    },
  },
  computed: {},
  methods: {
    prg(ev) {
      if(ev === 100){
          this.loading = false;
          this.$emit('done')
      }
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);
            pdf.setFontSize(9);
            pdf.setTextColor(150);
            pdf.text(
              "Página " + i + " de " + totalPages,
              pdf.internal.pageSize.getWidth() * 0.83,
              0.7
            );
          }
        });
        this.afterDownload();
    },
    afterDownload(){
      console.log("afterDonwload")
      this.$emit("afterDownload")
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/default.scss";
.relatorio {
  width: 700px;
  margin: 0 auto;
  background: white;
  padding-top: 10px;
  .header {
    border-bottom: 1px solid $primary;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    img {
      width: 200px;
    }
    .print_info {
      font-size: 10px;
    }
  }
}
</style>